import { getDataAttr, executeDependingOnDevice } from "./getDataAttrWithDeviceCheck";

export class Accordion {
	constructor() {
		this.CLASS_NAME_OPENED = "is-opened";
		this.SELECTOR_NAME_ROOT = ".js-acc-root";
		this.SELECTOR_NAME_ACC = ".js-acc";
		this.SELECTOR_NAME_TRIGGER = ".js-acc-trigger";
		this.SELECTOR_NAME_CONTENT = ".js-acc-content";

		this.duration = 300;
		this.scrollBehavior = "smooth"; // "smooth" | "instant" | "auto"

		// クエリパラメータによってidを集める
		const url = new URL(window.location.href);
		const paramValue = url.searchParams.get(`acc-open`);
		this.paramIDArray = paramValue ? paramValue.split(",") : [];

		// 簡易的に開く処理
		this.openSimply = (_content, _acc) => {
			const content = _content;
			content.style.height = "auto";
			_acc.classList.add(this.CLASS_NAME_OPENED);
		};
	}

	/**
	 * アコーディオンを開く
	 * @param {Element} acc - .js-acc要素
	 * @param {string} closingHeight - 閉じている時の高さ(px, remなど)
	 */
	open(acc, _closingHeight) {
		const closingHeight = _closingHeight || "0px";
		const content = acc.querySelector(this.SELECTOR_NAME_CONTENT);
		content.style.height = "auto";
		const heightValue = `${content.offsetHeight}px`;
		content.animate({ height: [closingHeight, heightValue] }, { duration: this.duration });
		content.style.height = "auto";
		acc.classList.add(this.CLASS_NAME_OPENED);
	}

	/**
	 * アコーディオンを閉じる
	 * @param {Element} acc - .js-acc要素
	 * @param {string} closingHeight - 閉じている時の高さ(px, remなど)
	 */
	close(acc, _closingHeight) {
		const closingHeight = _closingHeight || "0px";
		const content = acc.querySelector(this.SELECTOR_NAME_CONTENT);
		const heightValue = `${content.offsetHeight}px`;
		content.style.height = heightValue;
		content.animate({ height: [heightValue, closingHeight] }, { duration: this.duration });
		content.style.height = closingHeight;
		acc.classList.remove(this.CLASS_NAME_OPENED);
	}

	/**
	 *
	 * @param {Element} self - clickされる要素
	 * @param {string} closingHeight - 閉じている時の高さ(px, remなど)
	 */
	clickHandle(self, closingHeight = "0px") {
		let anotherAcc = [];
		const clickedAcc = self.currentTarget.parentElement;
		const root = clickedAcc.parentElement;
		anotherAcc = [...root.children].filter((child) => child !== clickedAcc);

		if (clickedAcc.classList.contains(this.CLASS_NAME_OPENED)) {
			// 開いている状態であれば
			this.close(clickedAcc, closingHeight);
		} else {
			// 閉じている状態であれば
			if (anotherAcc.length > 0) {
				anotherAcc.forEach((acc) => {
					this.close(acc, closingHeight);
				});
			}
			this.open(clickedAcc, closingHeight);
		}
	}

	/**
	 * ルート要素に処理をかける
	 * @param {Element} root - .js-acc-root要素に処理をかける
	 */
	setRoot(root) {
		const accs = root.querySelectorAll(this.SELECTOR_NAME_ACC);
		if (accs.length === 0) {
			console.error(`${this.SELECTOR_NAME_ACC}が必要です`);
			return;
		}

		// 閉じている時の高さ
		const closingHeight = getDataAttr(root, "data-height") || "0px";

		// 各アコーディオン
		for (let i = 0; i < accs.length; i += 1) {
			const acc = accs[i];
			const triggers = acc.querySelectorAll(this.SELECTOR_NAME_TRIGGER);
			const content = acc.querySelector(this.SELECTOR_NAME_CONTENT);

			if (triggers.length === 0 || !content) {
				console.error(`${this.SELECTOR_NAME_TRIGGER}と${this.SELECTOR_NAME_CONTENT}が必要です`);
				return;
			}

			// パラメータによって開く
			if (acc.id !== "" && this.paramIDArray.includes(acc.id)) {
				this.openSimply(content, acc);
			} else {
				// とりあえず閉じる
				content.style.height = closingHeight;
			}

			// data-open属性の値によって開く
			executeDependingOnDevice(acc, "data-open", () => this.openSimply(content, acc));

			// トリガーにクリック処理を付与
			triggers.forEach((trigger) => {
				trigger.addEventListener("click", (self) => this.clickHandle(self, closingHeight));
			});

			// .js-acc-contentに閉じる処理をつける
			content.style.cursor = "pointer";
			content.addEventListener("click", (self) => this.clickHandle(self, closingHeight));
		}
	}

	init() {
		const roots = document.querySelectorAll(this.SELECTOR_NAME_ROOT);
		if (roots.length === 0) return;
		roots.forEach((root) => {
			this.setRoot(root);
		});
	}
}

export default Accordion;

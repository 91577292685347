import { mq } from "./_mediaQuery";

/**
 * data属性から値を取り出し、stringまたはnumberとして返す。
 * elm[data-**="pc時の値,sp時の値"]の場合、戻り値はデバイス判定し評価されたpc時の値かsp時の値を返す。
 * @param {Element} elm - 任意の要素
 * @param {string} dataName - 属性の名称
 * @param {"string" | "number"} type - 戻り値の型
 * @param {string} wedge - データ属性の値の区切り文字
 * @returns {string | number | null}
 */
export const getDataAttr = (elm, dataName, type = "string", wedge = ",") => {
	let returnValue = null;

	if (!elm.hasAttribute(dataName)) return returnValue;

	const attrValue = elm.getAttribute(dataName);
	let attrValueArray = [];

	switch (type) {
		case "number":
			attrValueArray = attrValue.split(wedge).map(Number);
			break;
		case "string":
			attrValueArray = attrValue.split(wedge).map(String);
			break;
		default:
			console.error("getDataAttrの第3引数はnumberかstringのみを取ります。");
			break;
	}

	const pcValue = attrValueArray[0];
	const spValue = attrValueArray[1];

	if (pcValue || spValue === "") returnValue = pcValue;
	if (spValue && mq("sp")) returnValue = spValue;

	return returnValue;
};

/**
 * データ属性によって、デバイス判定をして、その評価によって関数を実行する。
 * DOMモデル: elm[data-**="pc（またはsp）"]
 * @param {Element} elm - 任意の要素
 * @param {string} dataName - データ属性の名称
 * @param {function} fnOnPC - pc時に実行する関数
 * @param {function} fnOnSP - sp時に実行する関数（デフォルトでfnOnPCとする）
 * @returns
 */
export const executeDependingOnDevice = (elm, dataName, fnOnPC, fnOnSP = fnOnPC) => {
	if (!elm.hasAttribute(dataName)) return;

	const attrValue = elm.getAttribute(dataName);
	switch (attrValue) {
		case "pc":
			if (mq("pc")) fnOnPC();
			break;
		case "sp":
			if (mq("sp") || fnOnSP) fnOnSP();
			break;
		case "":
			fnOnPC();
			break;
		default:
			console.error("data-openの値はspかpc以外無効です");
			break;
	}
};

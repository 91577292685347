import { getDataAttr } from "./getDataAttrWithDeviceCheck";
import { IOFn } from "./_observer";

export class LoopSlide {
	constructor() {
		this.CLASS_NAME_RUNNING = "is-running";
		this.SELECTOR_ROOT = ".js-loopslide";
		this.SELECTOR_TRACK = ".js-loopslide-track";
		this.SELECTOR_CONTENT = ".js-loopslide-content";
	}

	static cloneChild(elm) {
		const children = [...elm.children];
		children.forEach((child) => {
			const clone = child.cloneNode(true);
			elm.appendChild(clone);
		});
	}

	setRoot(root) {
		const track = root.querySelector(this.SELECTOR_TRACK);
		const content = root.querySelector(this.SELECTOR_CONTENT);

		if (!track || !content) {
			console.error(`${this.SELECTOR_TRACK}や${this.SELECTOR_CONTENT}がありません。`);
			return;
		}

		// データ属性から速度を受け取る
		const duration = getDataAttr(root, "data-dur");
		if (duration) {
			track.style.animationDuration = duration;
		}

		LoopSlide.cloneChild(content);
		root.classList.add(this.CLASS_NAME_RUNNING);
	}

	init() {
		const roots = document.querySelectorAll(this.SELECTOR_ROOT);
		if (roots.length === 0) return;
		roots.forEach((root) => {
			this.setRoot(root);
		});
		IOFn(this.SELECTOR_ROOT, "0%", this.CLASS_NAME_RUNNING, false);
	}
}
export default LoopSlide;

/**
 * インターセクションオブザーバー
 * @param {String} _selector - 観測対象の要素のクラス名。 デフォルト値: ".js-target-io"
 * @param {String} _rootMargin - 観測の領域。 デフォルト値: "0% 0% -20% 0%"
 * @param {String} _classname - 観測領域に入ったときに、_selectorに追加されるクラス名。デフォルト値: "is-entering"
 * @param {Boolean} _cancelObservation - trueにすると一度しか観測しなくなる。デフォルト値: true
 * @returns
 */
export const IOFn = (_selector = ".js-target-io", _rootMargin = "0% 0% -20% 0%", _classname = "entered", _cancelObservation = true) => {
	const targets = document.querySelectorAll(_selector);
	if (!targets.length) return;

	const options = {
		root: null,
		rootMargin: _rootMargin,
		threshold: 0,
	};

	const observer = new IntersectionObserver((entries) => {
		for (let i = 0; i < entries.length; i += 1) {
			if (entries[i].isIntersecting) {
				entries[i].target.classList.add(_classname);
				if (_cancelObservation) observer.unobserve(entries[i].target);
			} else {
				if (_cancelObservation) return;
				entries[i].target.classList.remove(_classname);
			}
		}
	}, options);

	for (let i = 0; i < targets.length; i += 1) {
		observer.observe(targets[i]);
	}
};

export default IOFn;

/**
 * テキストである子要素一つ一つをタグで囲む関数。
 * @param {string} _className ターゲット要素となるクラス名、デフォルトで'.js-wc'。
 * @param {string} _tag 子要素に囲むタグ、デフォルトで span。
 * @returns
 * ターゲット要素 の data-className属性 で各一文字にその属性の値のクラス名を与える。
 * ターゲット要素 の data-aniDelay="初項, 公差" で各一文字の style属性：animation-delay にその属性の値に対応した等差数列の値（ms）を与える。
 * ターゲット要素 の data-traDelay="初項, 公差" で各一文字の style属性：transition-delay にその属性の値の対応した等差数列の値（ms）を与える。
 */
export const wrapCharacterFn = (_className = ".js-wc", _tag = "span") => {
	const target = document.querySelectorAll(_className);
	if (target.length === 0) return;

	/**
	 * 等差数列
	 * @param {number} _a 初稿
	 * @param {number} _d 公差
	 * @param {number} _i 連番（インデックス番号）
	 * @returns インデックス番号に対応した等差数列の値
	 */
	const arithmeticProgression = (_a, _d, _i) => _a + _d * _i;

	target.forEach((_target) => {
		const t = _target;
		let outputText = "";

		// data属性の情報
		const { classname, anidelay, tradelay } = t.dataset;
		const ad = typeof anidelay !== "undefined" && anidelay.split(",").map(Number);
		const td = typeof tradelay !== "undefined" && tradelay.split(",").map(Number);

		// 文字をタグで囲む
		/**
		 *
		 * @param {string} _char 一文字
		 * @param {number} _index 連番
		 * @returns
		 */
		const wrapChar = (_char, _index) => {
			let styleValue = "";

			const attachProperty = (_property, _a, _d) => {
				const value = arithmeticProgression(_a, _d, _index);
				return `${_property}: ${value}ms;`;
			};

			if (ad) styleValue += attachProperty("animation-delay", ad[0], ad[1]);
			if (td) styleValue += attachProperty("transition-delay", td[0], td[1]);

			const style = styleValue === "" ? "" : `style="${styleValue}"`;
			const cls = typeof classname === "undefined" ? "" : `class="${classname}"`;

			if (/\s/g.test(_char)) return `${_char}`; // 空白であればそのまま
			return `<${_tag} ${cls} ${style}>${_char}</${_tag}>`;
		};

		const nodes = [...t.childNodes];
		nodes.forEach((_node) => {
			if (_node.nodeType === 3) {
				// テキストの場合 span(などのタグ)で囲んで連結する。
				const textArray = _node.textContent.replace(/\r?\n/g, "").split(""); // 改行コード削除

				for (let i = 0; i < textArray.length; i += 1) {
					const char = textArray[i];
					outputText += wrapChar(char, i);
				}
			} else {
				// テキスト以外の場合(<br/>など) そのまま連結する。
				outputText += _node.outerHTML;
			}
		});

		t.innerHTML = outputText;
	});
};

export default wrapCharacterFn;

import { getDataAttr } from "./getDataAttrWithDeviceCheck";

/**
 * タブメニュー
 * 仕様1: 複数体系が存在しても大丈夫。
 * 仕様2: あらかじめ開くタブを決められる。.js-tab[data-index="2"]をつける。※0番目からカウントする！
 * 仕様3: URLパラメータによって開くタブを決められる。?tab-ID名=2の場合、.js-tab#ID名の2番目コンテンツが開かれる。
 * 仕様4: URLパラメータ > .js-tab[data-index="2"]
 * 仕様5: コンテンツ複数OK。
 * 仕様6: トリガー複数OK。
 * 仕様7: トリガーとコンテンツのはDOMの順番に準拠する。=> DOMの個数が合わないと不具合発生。
 * 仕様8: .js-無しは装飾のためのクラス。
 */
export class Tab {
	/**
	 * @param {Element} rootElm - ルートの要素
	 */
	constructor() {
		this.CLASS_NAME_ACTIVE = "is-active";
		this.SELECTOR_NAME_ROOT = ".js-tab";
		this.SELECTOR_NAME_TRIGGERS = ".js-tab-triggers";
		this.SELECTOR_NAME_CONTENTS = ".js-tab-contents";

		// クエリパラメータを取得
		const url = new URL(window.location.href);
		this.URLParams = url.searchParams;
	}

	/**
	 * i番目の要素を開く
	 * @param {object} targetElms - {triggers: トリガーのHTMLCollection, contents: コンテンツのHTMLCollection}
	 * @param {Number} i - 開く番号
	 */
	change(targetElms, i) {
		const changeFn = (wrapperElm) => {
			wrapperElm.forEach((_triggers) => {
				const { children } = _triggers;
				[...children].forEach((trigger) => {
					trigger.classList.remove(this.CLASS_NAME_ACTIVE);
				});
				if (!children[i]) {
					children[0].classList.add(this.CLASS_NAME_ACTIVE);
					console.error("指定した番号は無効です");
					return;
				}
				children[i].classList.add(this.CLASS_NAME_ACTIVE);
			});
		};
		changeFn(targetElms.triggers);
		changeFn(targetElms.contents);
	}

	setRoot(root) {
		const triggers = root.querySelectorAll(this.SELECTOR_NAME_TRIGGERS);
		const contents = root.querySelectorAll(this.SELECTOR_NAME_CONTENTS);

		if (triggers.length === 0 || contents.length === 0) {
			console.error(`${this.SELECTOR_NAME_TRIGGERS}と${this.SELECTOR_NAME_CONTENTS}が必要です`);
			return;
		}

		// 要素をまとめる
		const targetElms = {
			triggers,
			contents,
		};

		// 表示する番号
		let initialIndex = 0;

		// data-index属性によって番号を更新
		initialIndex = getDataAttr(root, "data-index", "number") || 0;

		// URLクエリパラメータによる番号の更新
		if (root.hasAttribute("id")) {
			const idName = root.id;
			const paramValue = this.URLParams.get(`tab-${idName}`) || 0;
			initialIndex = Number(paramValue);
		}

		// 初期実行
		this.change(targetElms, initialIndex);

		// トリガーにクリックイベントを付与
		triggers.forEach((_triggers) => {
			[..._triggers.children].forEach((trigger, i) => {
				trigger.addEventListener("click", () => {
					this.change(targetElms, i);
				});
			});
		});
	}

	init() {
		const roots = document.querySelectorAll(this.SELECTOR_NAME_ROOT);
		if (roots.length === 0) return;
		roots.forEach((root) => {
			this.setRoot(root);
		});
	}
}

export default Tab;

/**
 * 言語切り替えを補助するクラス。
 * 1. 言語のステータスを保持・管理する。
 * 2. スイッチを機能させる。
 * ここではhtmxモジュールをインポートしない（疎結合性のため）
 */
export class AidSwitchLanguage {
	constructor() {
		this.langArray = ["ja", "en"];
		this.defaultLang = "ja";
		this.paramKey = "lang";
		this.switchSelector = "select.js-switch-language";
		this.htmxSelector = `[hx-get*="/lang"]`;
		this.currentLang = this.setCurrentLang();
		if (this.currentLang !== "ja") this.setURLParamLang();
	}

	/**
	 * URLからタイトル言語ファイルの相対パスを取得する。
	 * @returns {string | null} 文字列: タイトル言語ファイルの相対パス
	 */
	getTitleFilePath() {
		let returnValue = null;

		const { titledir } = document.body.dataset;

		if (!titledir) return returnValue;

		if (titledir.endsWith("/")) {
			returnValue = `${titledir + this.currentLang}.html`;
		} else {
			console.error("data-titlePath属性の値は無効です");
			returnValue = null;
		}

		return returnValue;
	}

	/**
	 * URLパラメーターからlangの値を取り出す。
	 * @returns {string | null} langの値 | 該当しなかった場合nullを返す
	 */
	getURLParamLang() {
		let returnValue = null;

		const URL_STRING = window.location.href;
		const url = new URL(URL_STRING);
		const parms = url.searchParams;

		if (!parms.has(this.paramKey)) return returnValue;

		if (!this.langArray.includes(parms.get(this.paramKey))) return returnValue;

		returnValue = parms.get(this.paramKey);

		return returnValue;
	}

	/**
	 * ローカルストレージのlangの値を取得する
	 * @returns {null | string} langがなければnullを返す
	 */
	getLocalStrageLang() {
		let returnValue = null;
		const LS_VALUE = localStorage.getItem(this.paramKey);
		returnValue = LS_VALUE || null;
		return returnValue;
	}

	/**
	 * ローカルストレージのlangに値を登録する
	 * @param {"ja" | "en"} value - ローカルストレージのlangに登録する値
	 * @returns {null | string}  ローカルストレージに登録した値を返す
	 */
	setLocalStrageLang(value) {
		let returnValue = null;
		localStorage.setItem(this.paramKey, value);
		returnValue = this.getLocalStrageLang();
		return returnValue;
	}

	/**
	 * URLにlangパラメータを付与する
	 */
	setURLParamLang() {
		const params = new URLSearchParams(window.location.search);
		params.set(this.paramKey, this.currentLang);
		const newUrl = `${window.location.pathname}?${params.toString()}`;
		window.history.pushState({}, "", newUrl);
	}

	/**
	 * URLパラメータとローカルストレージから現在の言語を定義する。
	 * パラメータ優先。
	 * @returns {"ja" | "en"}
	 */
	setCurrentLang() {
		let returnValue = this.defaultLang;

		const PARAM_LANG_VALUE = this.getURLParamLang();
		const LS_LANG_VALUE = this.getLocalStrageLang();

		// パラメータにlangがある場合
		if (PARAM_LANG_VALUE) {
			this.setLocalStrageLang(PARAM_LANG_VALUE);
			returnValue = this.getLocalStrageLang();
			return returnValue;
		}

		// パラメータにlangがなく、ローカルストレージにlangがあった場合
		if (LS_LANG_VALUE) {
			returnValue = LS_LANG_VALUE;
			return returnValue;
		}

		// ローカルストレージにlangがなく、パラメータにlangがない場合
		returnValue = this.defaultLang;
		this.setLocalStrageLang(returnValue);
		return returnValue;
	}

	/**
	 * 切り替えトリガー(スイッチ)にイベントを付与する
	 * @param {function(lang)} eventHandle - チェンジイベント時、言語ステータスが変わった後に実行する関数。その関数は選択された言語を引き取ることができる。
	 * @returns void
	 */
	setEventSwitch(
		eventHandle = () => {
			console.info("スイッチにつけるイベントを当ててください");
		},
	) {
		const switchs = document.querySelectorAll(this.switchSelector);
		if (switchs.length === 0) return;
		switchs.forEach((_switch) => {
			const swt = _switch;
			swt.value = this.currentLang;
			swt.addEventListener("change", () => {
				const selectedValue = swt.value;
				this.currentLang = this.setLocalStrageLang(selectedValue);
				this.setURLParamLang();
				eventHandle(this.currentLang);
			});
		});
	}

	/**
	 * 言語系htmx要素のhx-get属性のパスを、言語に対応したパスに変換する。
	 * @returns void
	 */
	changeAttrGetPath() {
		const htmxElms = document.querySelectorAll(this.htmxSelector);
		if (htmxElms.length === 0) return;

		htmxElms.forEach((elm) => {
			const htmxPath = elm.getAttribute("hx-get");
			const replacedPath = htmxPath.replace(/\/([^/]+)\.html$/, `/${this.currentLang}.html`);
			elm.setAttribute("hx-get", replacedPath);
		});
	}
}

export default AidSwitchLanguage;

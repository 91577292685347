// 共通js

import "../sass/common.scss"; // 共通css

import htmx from "htmx.org";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import SimpleBar from "simplebar";
import "simplebar/dist/simplebar.css";

import { setClickHandle, setScrollHandle } from "../jsmodules/toggleClassName";
import { IOFn } from "../jsmodules/_observer";
import { SmoothScroll } from "../jsmodules/smoothScroll";
import { AidSwitchLanguage } from "../jsmodules/aidSwitchLanguage";
import { Modal } from "../jsmodules/modal";
import { Tab } from "../jsmodules/tab";
import { Accordion } from "../jsmodules/accordion";
import { LoopSlide } from "../jsmodules/loopslide";
import { wrapCharacterFn } from "../jsmodules/_wrapChar";

gsap.registerPlugin(ScrollTrigger);

// スムーズスクロールのインスタンス
const ss = new SmoothScroll();
ss.watch();

// アコーディオンのインスタンス
const acc = new Accordion();

// ループスライドのインスタンス
const ls = new LoopSlide();

// タブメニューのインスタンス
const tab = new Tab();

// スクロールバー
const scrollbarFn = () => {
	const targets = document.querySelectorAll(".js-sb");
	if (targets.length === 0) return;

	// prettier-ignore
	Array.prototype.forEach.call(
		targets,
		(target) => new SimpleBar(target, {
				autoHide: false,
				scrollbarMaxSize: 150,
			}),
	);
};

// フッター付近に近づくとクラスがつく
const bottomoutFn = () => {
	const footer = document.getElementById("footer");
	if (!footer) return;

	const { body } = document;
	const CLASS_NAME_BOTTOMOUT = "is-bottomout";

	const observer = new IntersectionObserver(
		(entries) => {
			for (let i = 0; i < entries.length; i += 1) {
				if (entries[i].isIntersecting) {
					body.classList.add(CLASS_NAME_BOTTOMOUT);
				} else {
					body.classList.remove(CLASS_NAME_BOTTOMOUT);
				}
			}
		},
		{
			root: null,
			rootMargin: "0% 0% -20% 0%",
			threshold: 0,
		},
	);

	observer.observe(footer);
};

// タイトルのアニメーション
const animeTitle = () => {
	const title = document.querySelectorAll(".js-anime-title");
	if (title.length === 0) return;
	wrapCharacterFn(".js-anime-title .title-en");
	IOFn(".js-anime-title");
};

// ▼▼▼▼▼ DOMが安定した後に実行
const HTMX_ELM_COUNT = htmx.findAll(`[hx-trigger="load"]`).length;
if (HTMX_ELM_COUNT > 0) {
	let LOADED_COUNT = 0;

	// 多言語切り替えのインスタンス
	const asl = new AidSwitchLanguage();

	// タイトルを変える
	const changeTitle = () => {
		const path = asl.getTitleFilePath();
		if (!path) return;

		htmx.ajax("GET", path, {
			swap: "none",
		});
	};

	// 各テキストを変える
	const changeTexts = () => {
		const htmxElms = document.querySelectorAll(asl.htmxSelector);
		if (htmxElms.length === 0) return;
		asl.changeAttrGetPath();
		htmxElms.forEach((elm) => {
			htmx.process(elm);
			const htmxPath = elm.getAttribute("hx-get");
			htmx.trigger(elm, "click", { params: { _hx_get: htmxPath } });
		});
	};

	// セレクト要素がセレクトされた時の処理
	const changeLang = () => {
		const { body } = document;

		changeTitle();
		changeTexts();
		body.setAttribute("data-lang", asl.currentLang);
		document.documentElement.setAttribute("lang", asl.currentLang);
	};

	changeTitle();

	htmx.on("htmx:afterSettle", () => {
		LOADED_COUNT += 1;

		if (HTMX_ELM_COUNT === LOADED_COUNT) {
			// ここにDOMが安定した後に実行したい処理を記述する
			setClickHandle(".js-menu-trigger", "body", "opened-menu");
			setScrollHandle("header", "body");
			ss.addClassNameAllowsJump();
			asl.setEventSwitch(changeLang);
			changeLang();
			ss.setJumpInpageToTop();
			document.body.classList.add("htmx-settled");
			bottomoutFn();
			scrollbarFn();
		}
	});
}
// ▲▲▲▲▲

// body要素の右クリックメニューを無効化
if (!window.location.pathname.endsWith("cheatsheet.html")) {
	document.body.oncontextmenu = function () {
		return false;
	};
}

document.addEventListener("DOMContentLoaded", () => {
	const modal = new Modal();

	IOFn();
	IOFn(".js-fadein");
	modal.init();
	acc.init();
	ss.setJumpInpage();
	ls.init();
	tab.init();
	animeTitle();
});

import { throttle } from "./_throttle";

/**
 * 任意の要素をクリックすると、任意の要素にクラスがついたり取れたりする関数
 * @param {string} _trigger クリックの対象となる要素のクラス名
 * @param {dom | string} _target クラスがトグルする要素のクラス名 デフォルトで_triggerと同値
 * @param {function | null | false} _function クリック時についで実行する関数
 * @param {string} _classname トグルするクラス名、デフォルトで’is-active’
 */
export const setClickHandle = (_trigger, _target = _trigger, _classname = "is-active", _function) => {
	const trigger = document.querySelectorAll(_trigger);
	if (trigger.length === 0) return;

	// eslint-disable-next-line no-console
	let exec = () => console.log("ターゲット要素は無効です");

	// ターゲットがdomであれば
	if (_target instanceof HTMLElement) exec = (t) => t.classList.toggle(_classname);

	// ターゲットがストリングであり、その要素があれば
	if (typeof _target === "string") {
		exec = (t) => {
			const TARGET = document.querySelectorAll(t);
			if (TARGET.length === 0) return;
			TARGET.forEach((_t) => _t.classList.toggle(_classname));
		};
	}

	// クリックイベント
	trigger.forEach((_trg) => {
		_trg.addEventListener("click", () => {
			exec(_target);
			if (_function) _function();
		});
	});
};

/**
 * スクロールダウンするとクラスが付き、スクロールアップするとクラスが外れる関数
 * @param {string} _triggerID イベント発火タイミングの基準となる要素のID。この要素の位置（縦方向）をスクロール量が越すと発火する。
 * @param {string | null | false} _targetSelector クラスがトグルする要素のセレクタ。falsyな値であれば、トグルする要素は「_triggerID」の要素となる。
 * @param {string} _classname トグルするクラス名、デフォルトで’is-scrolled’
 * @param {number} _gap 発火開始までのマージンギャップ。
 * @returns
 */
export const setScrollHandle = (_triggerID, _targetSelector = null, _classname = "is-scrolled", _gap = 0) => {
	const trigger = document.getElementById(_triggerID);
	if (!trigger) return;

	const target = _targetSelector ? document.querySelectorAll(_targetSelector) : trigger;
	const triggerHeight = trigger.clientHeight + _gap;

	let offset = 0;
	let lastPosition = 0;
	let ticking = false;

	const throttleScrollHandle = throttle(() => {
		if (!ticking) {
			requestAnimationFrame(() => {
				lastPosition = window.scrollY;
				if (lastPosition > triggerHeight) {
					target.forEach((trg) => {
						if (lastPosition >= offset) trg.classList.add(_classname);
						if (lastPosition < offset) trg.classList.remove(_classname);
					});
					offset = lastPosition;
				}
				ticking = false;
			});
			ticking = true;
		}
	}, 200);

	window.addEventListener("scroll", throttleScrollHandle);
};

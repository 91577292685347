/**
 * スロットル: 指定時間毎に実行
 * @param {function} func - 実行処理
 * @param {number} delay - 実行間隔
 */
export function throttle(func, delay = 100) {
	let lastCall = 0;
	return function (...args) {
		const now = new Date().getTime();
		if (now - lastCall >= delay) {
			lastCall = now;
			func.apply(this, args);
		}
	};
}

export default throttle;

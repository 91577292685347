/**
 * オブジェクトをマージする
 * @param {object} target - 空オブジェクト推奨
 * @param  {...object} objs - マージするオブジェクト
 * @returns {object} マージしたオブジェクト
 */
export const margObjectFn = (_target = {}, ...objs) => {
	let returnObj = {};

	const target = _target;

	for (let i = 0; i < objs.length; i += 1) {
		const obj = objs[i];
		for (const key in obj) {
			if (typeof obj[key] === "object" && obj[key] !== null) {
				target[key] = this.mergeObject(target[key] || {}, obj[key]);
			} else {
				target[key] = obj[key];
			}
		}
	}

	returnObj = target;

	return returnObj;
};

export default margObjectFn;
